import request from '@/utils/request';

export function currentUser() {
  return request({
    url: '/auth/get-info',
    method: 'post'
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  });
}
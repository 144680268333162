const routes = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    meta: { middleware: 'AuthAdmin|AuthDashboard' },
    component: () =>
    import("@/views/dashboard/Dashboard"),
  }
]

export default routes;

<template>
  <span v-if="text">{{text|t}}</span>
</template>

<script>
export default {
  props: {
    id: {
      type: Number|String,
      default:() => null
    },
  },
  computed: {
    text(){
      if(this.formOptions && this.formOptions.length > 0 && this.id){
        return this.formOptions.find(item => item.id == this.id);
      }
      return null;
    }
  }
}
</script>
import { 
  residentsCount, 
  residentsCountRegions,
  getSumAllIncomes,
  getSumExportIncomes,
  getEmployeesCount,
  getCompaniesByIndustries,
  getResidentsByQuarter,
  getExportersByQuarter,
  getEducationsByQuarter,
  getEmployeesByQuarter,
  getOwnershipByQuarter,
  getIncomesByQuarter,
  getExportIncomesByQuarter,
  getResidentExportByCountries,
  getResidentsMainIndicators,
  residentsCountByRegions,

  getResidentStatisticsResidentCount,
  getResidentStatisticsResidentEmployeesCount,
  getResidentStatisticsResidentServiceCount,
  getResidentStatisticsResidentExportStatistics,
  getResidentStatisticsResidentCountByFormType,
  getResidentStatisticsResidentCountByCategory,
  getResidentStatisticsResidentExportCountries,
  getResidentStatisticsResidentForeignFounders,
  getResidentStatisticsTopTwentyResidents,
  getExporterResidentsStatistics,
  getQuarterIncomesMetrics,
} from '@/api/statistics';

export const actions = {
  getResidentsCount({ commit }, params) {
    return residentsCount(params);
  },
  getResidentsCountRegions({ commit },params) {
    return residentsCountRegions(params);
  },
  getResidentsCountByRegions({ commit },params) {
    return residentsCountByRegions(params);
  },
  getSumAllIncomes({ commit },params) {
    return getSumAllIncomes(params);
  },
  getSumExportIncomes({ commit },params) {
    return getSumExportIncomes(params);
  },
  getEmployeesCount({ commit },params) {
    return getEmployeesCount(params);
  },
  getCompaniesByIndustries({ commit },params) {
    return getCompaniesByIndustries(params);
  },
  getResidentsByQuarter({ commit },params) {
    return getResidentsByQuarter(params);
  },
  getExportersByQuarter({ commit },params) {
    return getExportersByQuarter(params);
  },
  getEducationsByQuarter({ commit },params) {
    return getEducationsByQuarter(params);
  },
  getEmployeesByQuarter({ commit },params) {
    return getEmployeesByQuarter(params);
  },
  getOwnershipByQuarter({ commit },params) {
    return getOwnershipByQuarter(params);
  },
  getIncomesByQuarter({ commit },params) {
    return getIncomesByQuarter(params);
  },
  getExportIncomesByQuarter({ commit },params) {
    return getExportIncomesByQuarter(params);
  },
  getResidentExportByCountries({ commit },params) {
    return getResidentExportByCountries(params);
  },
  getResidentsMainIndicators({ commit },params) {
    return new Promise((resolve, reject) => {
      getResidentsMainIndicators(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MAIN_INDICATORS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  
  getResidentStatisticsResidentCount({ commit },params) {
    return getResidentStatisticsResidentCount(params);
  },
  getResidentStatisticsResidentEmployeesCount({ commit },params) {
    return getResidentStatisticsResidentEmployeesCount(params);
  },
  getResidentStatisticsResidentServiceCount({ commit },params) {
    return getResidentStatisticsResidentServiceCount(params);
  },
  getResidentStatisticsResidentExportStatistics({ commit },params) {
    return getResidentStatisticsResidentExportStatistics(params);
  },
  getResidentStatisticsResidentCountByFormType({ commit },params) {
    return getResidentStatisticsResidentCountByFormType(params);
  },
  getResidentStatisticsResidentCountByCategory({ commit },params) {
    return getResidentStatisticsResidentCountByCategory(params);
  },
  getResidentStatisticsResidentExportCountries({ commit },params) {
    return getResidentStatisticsResidentExportCountries(params);
  },
  getResidentStatisticsResidentForeignFounders({ commit },params) {
    return getResidentStatisticsResidentForeignFounders(params);
  },
  getResidentStatisticsTopTwentyResidents({ commit },params) {
    return getResidentStatisticsTopTwentyResidents(params);
  },
  getExporterResidentsStatistics({ commit }, params) {
    return getExporterResidentsStatistics(params);
  },
  getQuarterIncomesMetrics({commit}, params) {
    return getQuarterIncomesMetrics(params);
  }
};
